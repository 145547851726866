<template>
  <div>
    <UsersubgroupAttMonitoring :usersubgroupsIds="usersubgroups" />
  </div>
</template>

<script>
import UsersubgroupAttMonitoring from "./UsersubgroupsAttMonitoring.vue";
export default {
  props: ["usersubgroupsIds"],
  async created() {
    this.usersubgroups = this.usersubgroupsIds;
    if (!this.usersubgroupsIds)
      this.usersubgroups.push(this.$route.params.usersubgroup);
  },
  components: {
    UsersubgroupAttMonitoring,
  },
  data() {
    return {
      fetchData: false,
      usersubgroups: [],
    };
  },
  methods: {},
};
</script>

<style >
.attainment .list-group .list-group-item {
  font-family: "Roboto Regular";
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  font-size: 14px;
  font-weight: 600;
}
</style>
