<template>
  <div>
    <b-container style="text-align: center; " class="mt-2 pt-2">
      <b-row>
        <b-col>
          <h2 style="font-size: 20px !important; color: #625e5e;">
            Attendance Monitoring
          </h2>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="mt-2 pt-2">
      <b-row>
        Choose date range (optional):
      </b-row>
      <b-row>
        <b-col md="5">
          <label for="example-datepickerfrom">From:</label>
          <b-form-datepicker id="example-datepickerfrom" v-model="date_from" class="mb-2"></b-form-datepicker>
        </b-col>
        <b-col md="5">
          <label for="example-datepickerto">To:</label>
          <b-form-datepicker id="example-datepickerto" v-model="date_upto" class="mb-2"></b-form-datepicker>
        </b-col>
        <b-col md="2">
          <b-button pill variant="outline-success" @click="getSubgroupsSchedules">Go</b-button>
        </b-col>
      </b-row>
    </b-container>
    <b-skeleton-table v-if="fetchData" :rows="3" :columns="2"
      :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
    <b-container v-if="readydata" class="mt-2">
      <b-row style="text-align:center;">
        <b-col md="12">


          <b-row class="mx-2">
            <div style="border:none;margin-bottom: 1px;"> <span class="px-3 holiday-slot  mr-1 border-dark"
                style="border: 2px solid;"></span>Holiday.
            </div>
            <div class="ml-2" style="border:none;margin-bottom: 1px;"> <span
                class="px-3 suspended-slot  mr-1 border-dark" style="border: 2px solid;"></span>Class Suspended.
            </div>
          </b-row>
          <table class="table-bordered table table-striped">
            <thead class="">
              <tr>
                <th rowspan="2">Date</th>
                <th :colspan="Object.keys(sessions).length + 1"
                  v-for="(usersubgroup, usersubgroupIndex) in usersubgroups" :key="usersubgroupIndex">
                  {{ usersubgroup.name }}
                </th>
              </tr>
              <tr>
                <th v-for="(session, sessionIndex) in sessions" :key="sessionIndex">
                  {{ session.name }}
                </th>
                <th>Students Summery Alert</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(date, dateIndex) in attendanceData" :key="dateIndex"
                :class="!date.day_id ? 'border border-info' : ''">
                <td>{{ dateIndex }} {{ weekdayNames[new Date(dateIndex).getDay()] }}</td>

                <td v-for="(tableindex, tIndex) in subgroupsSessionsTableIndex" :key="tIndex"
                  :class="{ 'holiday-slot': dateIndex in subgroups_holidays[tableindex.subgroup], 'suspended-slot': (dateIndex in subgroups_suspends[tableindex.subgroup] ? (subgroups_suspends[tableindex.subgroup][dateIndex].session_id.includes(Number(tableindex.session))) : false) }"
                  v-b-popover.hover.top="(dateIndex in subgroups_holidays[tableindex.subgroup] ? 'Holiday: ' + subgroups_holidays[tableindex.subgroup][dateIndex] : '') +
                  (dateIndex in subgroups_suspends[tableindex.subgroup] ? ' Class Suspended: ' + subgroups_suspends[tableindex.subgroup][dateIndex].name : '')">
                  <!-- its a normal session column or column for summary on each subgroup -->
                  <div v-if="tableindex.session">
                    <!-- if there is an attendance count  -->
                    <div v-if="
                      date[tableindex.subgroup][tableindex.session]
                        .present +
                      date[tableindex.subgroup][tableindex.session]
                        .absent >
                      0
                    ">
                      <span v-for="(marking, uIndex) in date[
                        tableindex.subgroup
                      ][tableindex.session].markedings" :key="uIndex">
                        {{ marking.user }}-{{ marking.course }}, </span><br />
                      <span>
                        {{
                            date[tableindex.subgroup][tableindex.session]
                              .present
                        }}/{{
    date[tableindex.subgroup][tableindex.session]
      .present +
    date[tableindex.subgroup][tableindex.session]
      .absent
}}
                      </span>
                    </div>
                    <span style="color: rgb(114, 0, 0);" v-if="(schedules[dateIndex][tableindex.subgroup][
                      tableindex.session
                    ].length < 1 && !(dateIndex in subgroups_holidays[tableindex.subgroup]))"> Timetable missing</span>
                    <div style="color: rgb(114, 0, 0);" v-if="
                      date[tableindex.subgroup][tableindex.session]
                        .present +
                      date[tableindex.subgroup][tableindex.session]
                        .absent <
                      1 &&
                      schedules[dateIndex][tableindex.subgroup][
                        tableindex.session
                      ].length
                    ">
                      <span v-for="(schedule, scheduleIndex) in schedules[dateIndex][
                        tableindex.subgroup
                      ][tableindex.session]" :key="scheduleIndex">
                        {{ schedule.user }}-{{ schedule.course }}
                        <span v-if="!(dateIndex in subgroups_holidays[tableindex.subgroup])" v-b-popover.hover.top="
                        'Sent alert to ' +
                        schedule.user +
                        ': Please update attendance for ' +
                        schedule.course +
                        ' on ' +
                        dateIndex +
                        ' for session ' +
                        sessions[tableindex.session].name +
                        '.'" @click="sentFacultyAlert(
    schedule.schedule_id,
    tableindex.session,
    dateIndex,
    tableindex.subgroup
  )" style="color: #2929aa; text-decoration: underline; cursor: pointer;">
                          Alert<b-spinner v-if="
                            spinnerPrograssids.includes(
                              'FA' +
                              dateIndex +
                              tableindex.subgroup +
                              tableindex.session +
                              schedule.schedule_id
                            )
                          " small></b-spinner></span>
                      </span>
                    </div>
                  </div>
                  <div v-else>
                    <span v-b-popover.hover.top="
                      '<student_name> is marked absent on ' +
                      date.date +
                      ' for sessions <sessions>.'
                    ">
                      Alert to
                      <span @click="
                        sentStudentDailySummaryAlert(
                          tableindex.subgroup,
                          dateIndex,
                          'students'
                        )
                      " style="color: #2929aa; text-decoration: underline; cursor: pointer;">students
                        <b-spinner v-if="
                          spinnerPrograssids.includes(
                            'SA' +
                            'students' +
                            dateIndex +
                            tableindex.subgroup
                          )
                        " small></b-spinner></span>
                      or
                      <span @click="
                        sentStudentDailySummaryAlert(
                          tableindex.subgroup,
                          dateIndex,
                          'parents'
                        )
                      " style="color: #2929aa; text-decoration: underline; cursor: pointer;">parents<b-spinner v-if="
  spinnerPrograssids.includes(
    'SA' + 'parents' + dateIndex + tableindex.subgroup
  )
" small></b-spinner>
                      </span>
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  props: ["usersubgroupsIds"],
  async created() {
    // this.getSubgroupsSchedules();
  },
  components: {},
  data() {
    return {
      fetchData: false,
      readydata: false,
      date_from: null,
      date_upto: null,
      attendanceData: {},
      schedules: {},
      sessions: {},
      usersubgroups: {},
      subgroupsSessionsTableIndex: [],
      spinnerPrograssids: [], // if of enabled spinners
      weekdayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      subgroups_holidays: {},
      subgroups_suspends: {},
    };
  },
  methods: {
    async getSubgroupsSchedules() {
      this.attendanceData = {};
      this.schedules = {};
      this.sessions = {};
      this.usersubgroups = {};
      this.subgroupsSessionsTableIndex = [];

      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/attendancereports/usersubgroups/monitoring`;
      await this.$axios
        .post(url, {
          subgroups: this.usersubgroupsIds,
          start_date: this.date_from,
          upto_date: this.date_upto,
        })
        .then((response) => {
          this.attendanceData = response.data.attendanceData;
          this.schedules = response.data.schedules;
          this.sessions = response.data.sessions;
          this.usersubgroups = response.data.usersubgroups;
          this.subgroups_holidays = response.data.subgroups_holidays;
          this.subgroups_suspends = response.data.subgroups_suspends;
          // this.fetchData = false;
          for (var usgkey in this.usersubgroups) {
            for (var sskey in this.sessions) {
              this.subgroupsSessionsTableIndex.push({
                subgroup: usgkey,
                session: sskey,
              });
            }
            // last column not for session but for summary
            this.subgroupsSessionsTableIndex.push({
              subgroup: usgkey,
              session: null,
            });
          }
        })
        .catch((error) => {
          this.fetchData = false;
          error;
        });
      // await this.prepareMonitoringData();
      this.fetchData = false;
      this.readydata = true;
    },
    async sentFacultyAlert(scheduleId, sessionId, date, subgroup) {
      let spinnerId = "FA" + date + subgroup + sessionId + scheduleId;
      this.spinnerPrograssids.push(spinnerId);
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/attendancereports/monitoring/facultyalert`;
      await this.$axios
        .post(url, { schedule: scheduleId })
        .then((response) => {
          if (response.data.status == "success")
            this.$toast.success("Alert sent successfully", {
              position: "top",
              duration: 3000,
            });
          if (response.data.status != "success")
            this.$toast.error("Error: please check sms transaction logs", {
              position: "top",
              duration: 3000,
            });
        })
        .catch((error) => {
          let errormesage = "Failed to Sent alert";
          if (error.response) errormesage = error.response.data.message;
          this.$toast.error(errormesage, {
            position: "top",
            duration: 3000,
          });
        });
      this.spinnerPrograssids.splice(
        this.spinnerPrograssids.indexOf(spinnerId),
        1
      );
    },
    async sentStudentDailySummaryAlert(subgroup, dateIndex, alertTo) {
      // Alert to students or parents
      if (
        confirm(
          `SMS alert will now be send to ${alertTo} phone number: click OK to confirm`
        ) != true
      ) {
        return;
      }
      let spinnerId = "SA" + alertTo + dateIndex + subgroup;
      this.spinnerPrograssids.push(spinnerId);
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/attendancereports/monitoring/usersubgroup/studentdailyalert`;
      await this.$axios
        .post(url, {
          usersubgroup: subgroup,
          date: dateIndex,
          alert_to: alertTo,
        })
        .then((response) => {
          if (response.data.status == "success")
            this.$toast.success("Alert sent successfully", {
              position: "top",
              duration: 3000,
            });
          if (response.data.status != "success")
            this.$toast.error("Error: please check sms transaction logs", {
              position: "top",
              duration: 3000,
            });
        })
        .catch((error) => {
          let errormesage = "Failed to download";
          if (error.response) errormesage = error.response.data.message;
          this.$toast.error(errormesage, {
            position: "top",
            duration: 3000,
          });
        });
      this.spinnerPrograssids.splice(
        this.spinnerPrograssids.indexOf(spinnerId),
        1
      );
    },
  },
};
</script>

<style >
.holiday-slot {
  background-color: rgb(222, 222, 120);
}

.suspended-slot {
  background-color: rgb(255, 181, 181) !important;
}

.table td {
  padding: 0.25rem;
}
</style>
